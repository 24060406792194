import * as React from "react";
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useEffect, useState } from "react";

export default function Bluebox(props) {
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        fetchEventSource(`/api/infer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'text/event-stream',
            },
            body: JSON.stringify({
                messages: [
                    { role: 'system', content: 'You are an esoteric freak' },
                    { role: 'user', content: 'what is the meaning of life in 40 words' }
                ],
                model: "@cf/meta/llama-3.1-8b-instruct",
                max_tokens: 150,
            }),
            onmessage(event) {
                if (event.data == "[DONE]") {
                    return;
                };
                const respData = JSON.parse(event.data);
                setMessages((messages) => [...messages, respData.response]);
            }
        });
    }, []);

    return (
        <div className="blue-box">
            {messages}
        </div>
    )
}