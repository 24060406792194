import './App.css';
import Bluebox from './components/bluebox'


function App() {
  return (
    <div className="book">
        <div class="page">
            <div class="main-content">
              <div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                < Bluebox />
              </div>
                <p>When I was a kid, I named this feeling Fuzhou Nighttime Feeling. It is not a cohesive thing, this feeling, it reaches out and bludgeons everything. It is excitement tinged by despair. It is despair heightened by glee. It is partly sexual in nature, though it precedes sexual knowledge. If Fuzhou Nighttime Feeling were a sound, it would be early/mid-nineties R&B. If it were a flavor, it would be the ice-cold Pepsi we drink as we turn down tiny alleyways where little kids defecate wildly. It is the feeling of drowning in a big hot open gutter, of crawling inside an undressed, unstanched wound that has never been cauterized. In my imagining, I return from New York. I do whatever my uncles say. I relearn Mandarin. I relearn Fujianese. I get married to another Fujianese. I live here, in beautiful, sunny, tropical Fuzhou, Fujan, fenced in by towering mountains and bounded by a boundless sea through which everyone leaves, where the palm trees sway and the nights run so late. I am so happy.</p>
                <div class="footnote">1. this is the deepest blue, talking, talking, always talking to you <span class="cursor"></span> </div>
            </div>
            <div class="container">
                <div class="box"></div>
                <div class="box"></div>
            </div>
        </div>
        <div class="page">
            <div class="main-content">
                <div class="float-box">
                    <p>12312312312312312311231231231.</p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </div>
                <div class="black-box"></div>
                <div class="blue-box" id="box2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. 
                </div>
                <p>.........</p>
            </div>
            <div class="right-column">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </div>
            <div class="constellation">
              <div class="star"></div>
              <div class="star"></div>
              <div class="star"></div>
              <div class="star"></div>
              <div class="star"></div>
              <div class="star"></div>
              <div class="star"></div>
              <div class="star"></div>
              <div class="star"></div>

              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
          </div>
        </div>
    </div>
  );
}

export default App;